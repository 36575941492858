"use client";
import React from "react";
import { RoundButton } from "../../../Basic/RoundButton";
import ArrowRightBoldIcon from "@/assets/icons/arrow-right-bold.svg";
import { useSwiper } from "swiper/react";

function HeroActionButtons() {
  const swiper = useSwiper();

  return (
    <div className="absolute bottom-20 left-20 hidden lg:flex z-10">
      <RoundButton onClick={() => swiper.slidePrev()}>
        <ArrowRightBoldIcon className="w-4 rotate-180 fill-primary endemic:fill-invert" />
      </RoundButton>
      <RoundButton onClick={() => swiper.slideNext()}>
        <ArrowRightBoldIcon className="w-4 fill-primary endemic:fill-invert" />
      </RoundButton>
    </div>
  );
}

export default HeroActionButtons;
