import { Profile } from "./types";
import { formatAddress } from "@/shared/helpers";

export function getProfileName(profile: Profile) {
  const address = profile.address;

  return (
    profile.fullName ||
    profile.name ||
    profile.displayName ||
    (address && formatAddress(address)) ||
    "unnamed"
  );
}
