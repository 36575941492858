"use client";
import React from "react";

export interface Props {
  isVisible?: boolean;
}

const Overlay = ({ isVisible }: Props) => {
  return (
    <>
      {isVisible ? (
        <div
          className={`bg-overlay light:bg-lightOverlay fixed w-full h-full z-10 top-0 right-0`}
        ></div>
      ) : null}
    </>
  );
};

export default Overlay;
