"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { SmallNewsItem } from "../SmallNewsItem";
import { SimpleBlogPost } from "@/api/contentful/types";
import "swiper/css";
import "swiper/css/pagination";
import { MoreItemsSectionHeader } from "@/app/(components)/Wrappers/MoreItemsSectionHeader";
import { twMerge } from "tailwind-merge";
import { getPostRoute } from "@/app/media/constants";

export interface Props {
  posts: SimpleBlogPost[];
  displayHeaderSection?: boolean;
  className?: string;
  title?: string;
  viewAllUrl?: string;
  displayHeaderSectionOnMobileOnly?: boolean;
}

const SmallNewsItemsList = ({
  posts,
  displayHeaderSection,
  className,
  title,
  viewAllUrl,
  displayHeaderSectionOnMobileOnly,
}: Props) => {
  return (
    <div className={className}>
      {(displayHeaderSection || displayHeaderSectionOnMobileOnly) && (
        <div
          className={twMerge(
            "px-5 lg:px-0",
            displayHeaderSectionOnMobileOnly && "lg:hidden",
          )}
        >
          <MoreItemsSectionHeader title={title} viewAllUrl={viewAllUrl} />
        </div>
      )}
      <div className={twMerge("pl-5 lg:px-0")}>
        <Swiper
          slidesPerView={1.2}
          spaceBetween={30}
          modules={[Pagination]}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {posts.slice(0, 3).map((post, index) => {
            return (
              <SwiperSlide key={index}>
                <SmallNewsItem
                  title={post.title}
                  href={getPostRoute(post.slug)}
                  imageSrc={post.image?.url}
                  imageAlt={post.image?.title}
                  postCategory={post.category}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default SmallNewsItemsList;
