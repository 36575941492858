import { Sizes } from "@/shared/constants";
import Link from "next/link";
import React from "react";
import { PostCategory } from "@/app/(components)/Basic/PostCategory";
import { PostCategoryType } from "@/api/contentful/types";
import { twMerge } from "tailwind-merge";
import { EndemicImage } from "@/app/(components)/Basic/Image";

export interface Props {
  titleElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "span";
  href: string;
  title: string;
  imageSrc?: string;
  imageAlt?: string;
  className?: string;
  postCategory?: PostCategoryType;
}

const SmallNewsItem = ({
  titleElement = "h3",
  href,
  title,
  imageSrc,
  imageAlt = "",
  className,
  postCategory,
}: Props) => {
  return (
    <Link href={href} className={className}>
      {/* TODO: Fix fonts */}
      <div className={"relative w-full aspect-[16/9]"}>
        {imageSrc && (
          <EndemicImage
            src={imageSrc}
            alt={imageAlt || title}
            fill
            className={"object-cover"}
            sizes={`
            320px,
            (min-width: ${Sizes.MD}) 400px,
            `}
            dynamic
          />
        )}
      </div>
      {postCategory && (
        <PostCategory category={postCategory} className="mt-2" />
      )}
      {React.createElement(
        titleElement,
        {
          className: twMerge(
            "pt-6 text-primary font-light text-[26px] leading-[32px] -tracking-tight md:text-[32px] md:leading-[36px]",
            postCategory && "pt-3",
          ),
        },
        title,
      )}
    </Link>
  );
};

export default SmallNewsItem;
