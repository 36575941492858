import { useQuery } from "@tanstack/react-query";
import nftsApi from "@/api/nfts/client";
import { DEFAULT_COLLECTION_SLIDER_LENGTH } from "@/shared/constants";

export const useCollectionSliderNfts = (
  address: string,
  count = DEFAULT_COLLECTION_SLIDER_LENGTH,
) => {
  return useQuery({
    queryKey: ["collection-slider-nfts", address, count],
    queryFn: async () => await nftsApi.getNftsForCollection(address, 0, count),
  });
};
