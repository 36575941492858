"use client";

import { Colors } from "@/shared/constants";
import { twMerge } from "tailwind-merge";
import { useState, useRef } from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import DefaultProfile from "@/assets/graphics/default-profile.svg";
import Link from "next/link";
import { User } from "@/api/users/types";
import { getUsersHref, getUsersName, sliceFullName } from "@/api/users/helpers";
import { ImageTypeHandler } from "@/app/(components)/Basic/ImageTypeHandler";
import { EndemicImage } from "@/app/(components)/Basic/Image";
import { Nft } from "@/api/nfts/types";

export interface Props {
  artist: User;
  featuredNfts?: Nft[];
  color: Colors;
  swap: boolean;
}

/* TODO: Refactor - ArtistItemCard (server) and ArtistItem (client) should be same component with showArt prop. */
const ArtistItem = ({ artist, featuredNfts = [], color, swap }: Props) => {
  SwiperCore.use([Autoplay, EffectFade]);

  const href = getUsersHref(artist);
  const name = artist.fullName ? sliceFullName(artist.fullName!) : [];

  const [isExpanded, setIsExpanded] = useState(false);

  const swiperRef = useRef<SwiperRef>(null);

  const initSwiper = () => {
    swiperRef?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseEnter = () => {
    setIsExpanded(true);
    swiperRef?.current?.swiper?.slideTo(1);
    swiperRef?.current?.swiper?.autoplay?.start();
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
    swiperRef?.current?.swiper?.autoplay?.stop();
    swiperRef?.current?.swiper?.slideTo(0);
  };

  return (
    <>
      {/* Overlay */}
      <div
        className={twMerge(
          "bg-white/[.8] z-50 fixed inset-0 hidden md:inline",
          !isExpanded && "md:hidden",
        )}
      ></div>

      <div
        className={twMerge(
          "flex justify-center md:flex-row md:items-center gap-y-2",
          swap ? "flex-col-reverse" : "flex-col",
        )}
      >
        <Link
          href={href}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={twMerge(
            "block relative w-full m-automd:m-0  md:max-w-[400px] aspect-square md:hover:scale-[1.2] md:hover:z-50 transition duration-500",
            swap && "order-1",
          )}
        >
          <Swiper
            className="h-full w-full"
            effect={"fade"}
            speed={1000}
            autoplay={{ delay: 1500 }}
            ref={swiperRef}
            onInit={initSwiper}
          >
            <SwiperSlide key={0}>
              {artist.imageUrl ? (
                <ImageTypeHandler
                  src={artist.imageUrl ? artist.imageUrl : ""}
                  placeholder={artist.imagePlaceholder}
                  alt={getUsersName(artist)}
                  fill
                  className="w-full object-cover"
                />
              ) : (
                <DefaultProfile />
              )}
            </SwiperSlide>
            {featuredNfts.map((nft, index) => (
              <SwiperSlide key={index + 1}>
                <EndemicImage
                  src={nft.thumbnail || nft.image}
                  placeholder={nft.placeholder}
                  alt={getUsersName(artist)}
                  fill
                  className="object-cover w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Link>
        {/* TODO: Fix fonts */}
        <Link
          href={href}
          className={twMerge(
            "md:relative transition-all duration-500 flex m-auto md:m-0 flex-col items-center md:basis-1/2 md:max-w-[400px]",
            swap ? "md:items-end md:right-10" : "md:items-start md:left-10",
            isExpanded && (swap ? "z-50 md:right-20" : "z-50 md:left-20"),
          )}
        >
          <span
            className={twMerge(
              "text-h2-sm lg:text-h2 leading-[32px] md:leading-[56px] font-light whitespace-nowrap pb-1",
              `gradient text-${color}`,
            )}
          >
            {name[0]}
          </span>
          <span
            className={twMerge(
              "text-h1-sm lg:text-h1 font-semibold whitespace-nowrap pb-1",
              `gradient text-${color}`,
            )}
          >
            {name[1]}
          </span>
          <div className="text-secondary text-body-3 mt-2 md:mt-6">
            {artist.displayName || "unnamed"}
          </div>
        </Link>
      </div>
    </>
  );
};

export default ArtistItem;
