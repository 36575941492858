"use client";
import React from "react";
import ArrowLeftBoldIcon from "@/assets/icons/arrow-left-bold.svg";
import ArrowRightBoldIcon from "@/assets/icons/arrow-right-bold.svg";
import Drips from "@/assets/graphics/drips.svg";
import { useSwiper } from "swiper/react";
import { twMerge } from "tailwind-merge";

export interface Props {
  isNext?: boolean;
}

const SwiperNavigationButton = ({ isNext = false }: Props) => {
  const swiper = useSwiper();

  return (
    <button
      className={twMerge(
        "cursor-pointer relative w-10 h-8 md:w-15 md:h-12 bg-yellow endemic:bg-primary flex items-center justify-center ml-2 md:ml-4",
        " hover:bg-[#E29917] group endemic:hover:bg-[#E29917] active:scale-95 ease-in-out duration-100",
      )}
      onClick={() => (isNext ? swiper.slideNext() : swiper.slidePrev())}
    >
      {isNext ? (
        <ArrowRightBoldIcon className="fill-primary night:fill-invert endemic:fill-invert w-3 md:w-4" />
      ) : (
        <ArrowLeftBoldIcon className="fill-primary night:fill-invert endemic:fill-invert w-3 md:w-4" />
      )}
      <Drips className="fill-yellow endemic:fill-primary absolute bottom-0 right-0 left-0 translate-y-full group-hover:fill-[#E29917] duration-100" />
    </button>
  );
};

export default SwiperNavigationButton;
