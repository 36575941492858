export type OptionValue = string | number;

export enum OptionPicker {
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
}
export interface Option<T extends OptionValue> {
  value: T;
  label: string | React.JSX.Element;
}
