import { formatUnits, parseUnits } from "ethers";
import { removeScientificNotation } from "./utilities";

const calculateCutFromPercent = (price: bigint, cut: bigint) => {
  return (price * cut) / BigInt(10000);
};

function calculateOriginalValue(valueBn: bigint, cut: bigint) {
  return (valueBn * BigInt(10000)) / (BigInt(10000) + cut);
}

export const addPercentageOnAmount = (amount: bigint, percentage: number) => {
  const amountPercentage = (amount * BigInt(percentage)) / BigInt(100);

  return amount + amountPercentage;
};

export const getDecimalsCount = (number?: number | string) => {
  if (!number) return 0;

  const decimalPart = number.toString().split(".")[1];

  if (!decimalPart) return 0;

  return decimalPart.length;
};

export const getStepForNumberInput = (requiredInput: number | undefined) => {
  if (!requiredInput) {
    return "1";
  }

  const decimalCount = getDecimalsCount(requiredInput);

  if (decimalCount === 0) {
    return "1";
  }

  let result = "0.";

  for (let i = 0; i < decimalCount; i++) {
    result += "0";
  }

  result += "1";

  return result;
};

export const calculateTotal = (
  price: number,
  makerFee?: bigint,
  royaltiesFee?: bigint,
  collectiveFee?: bigint,
) => {
  if (
    price === undefined ||
    makerFee === undefined ||
    royaltiesFee === undefined ||
    collectiveFee === undefined
  )
    return;

  const priceBn = BigInt(Math.round(price));

  const feeCut = calculateCutFromPercent(priceBn, makerFee);
  const royaltiesCut = calculateCutFromPercent(priceBn, royaltiesFee);
  const collectiveCut = calculateCutFromPercent(priceBn, collectiveFee);

  return priceBn - feeCut - royaltiesCut - collectiveCut;
};

const addTakerFee = (
  value: string | undefined,
  takerFee: number | undefined,
) => {
  if (
    value === undefined ||
    takerFee === undefined ||
    typeof value === "object"
  )
    return;

  const valueBn = BigInt(Math.round(+value));

  const takerFeeBn = BigInt(takerFee);

  const takerFeeCut = calculateCutFromPercent(valueBn, takerFeeBn);

  return valueBn + takerFeeCut;
};

const removeTakerFee = (
  value: string | undefined,
  takerFee: number | undefined,
) => {
  if (value === undefined || !takerFee || typeof value === "object") return;

  const valueBn = BigInt(Math.round(+value));
  const takerFeeBn = BigInt(takerFee);

  return calculateOriginalValue(valueBn, takerFeeBn);
};

const formatDecimals = (
  price?: number | string,
  decimals = 4,
  handleLowValues = true,
) => {
  if (price === undefined) return;

  if (handleLowValues && +price < 0.0001) return "<0.0001";

  return +parseFloat((+price).toFixed(decimals)).toLocaleString("en-US", {
    useGrouping: false,
    maximumFractionDigits: decimals,
  });
};

const roundToDecimal = (price?: number | string, decimals = 4) => {
  if (price === undefined) return;

  const factor = Math.pow(10, decimals);

  return Math.ceil(+price! * factor) / factor;
};

const toHighDenom = (
  value?: string | number | bigint,
  currencyDecimals = 18,
) => {
  if (value === undefined) return;

  const valueNb = Number(value);
  const rounded = removeScientificNotation(Math.round(valueNb).toString());
  return +formatUnits(BigInt(rounded), currencyDecimals);
};

const toLowDenom = (
  value?: number | string | bigint,
  currencyDecimals = 18,
) => {
  if (value === undefined || value === "") return;

  return parseUnits(value.toString(), currencyDecimals).toString();
};

const getDisplayPrice = (
  value?: string | number | bigint,
  currencyDecimals = 18,
  roundToDecimalCount = 4,
) => {
  const highDenomValue = toHighDenom(value, currencyDecimals);

  if (!highDenomValue) return;

  return formatDecimals(highDenomValue, roundToDecimalCount)?.toString();
};

const getFeePercentage = (rawFee: number) => {
  return rawFee ? rawFee / 100 : 0;
};

function getFeeCut(price: string | undefined, fee: number | undefined) {
  if (!price || !fee) return "0";
  const priceBn = BigInt(price);

  const result = (priceBn * BigInt(fee)) / BigInt(10000);
  return result.toString();
}

export {
  calculateCutFromPercent,
  formatDecimals,
  toHighDenom,
  toLowDenom,
  addTakerFee,
  removeTakerFee,
  getDisplayPrice,
  roundToDecimal,
  getFeePercentage,
  getFeeCut,
};
