import { useMemo } from "react";
import { getDisplayPrice } from "../currencies";

export default function useDisplayPrice(
  price?: string | number | bigint,
  decimals: number = 18,
  roundToDecimalCount = 4,
) {
  return useMemo(() => {
    const displayPrice = getDisplayPrice(price, decimals, roundToDecimalCount);

    if (!displayPrice) return;

    return +displayPrice;
  }, [decimals, price, roundToDecimalCount]);
}
