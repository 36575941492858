"use client";
import { ReactNode } from "react";
import { ThemedIcon } from "../Icon";
import { twMerge } from "tailwind-merge";
import { publicConfig } from "@/shared/config/constants";

export interface Props {
  children: ReactNode;
  id: string;
  className?: string;
}

const EndemicUserWrapper = ({ children, id, className }: Props) => {
  return (
    <>
      {id === publicConfig.endemicUserId ? (
        <div className={twMerge("flex items-center gap-x-2", className)}>
          {children}
          <ThemedIcon iconName="endy" className="w-4 h-4" />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default EndemicUserWrapper;
