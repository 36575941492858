import { twMerge } from "tailwind-merge";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const RoundButton = ({ children, ...props }: Props) => {
  return (
    <span
      className={twMerge(
        "shadow-[0_8px_24px_rgba(0,0,0,0.2)] border border-solid border-endemicGray-500 night:border-[#3e3e3e] flex items-center justify-center rounded-full w-20 h-20 p-2 bg-gradient-to-b from-white to-[#e7e7e7]   night:from-[#2b2b2b] night:to-endemicGray",
        " hover:from-[#f1f1f1] hover:to-[#dcdcdc]  night:hover:from-[#383838] night:hover:to-[#242424]",
        "active:scale-95 duration-100 ease-in-out",
      )}
    >
      <button
        {...props}
        className={twMerge(
          "text-primary rounded-full w-16 h-16 flex items-center justify-center bg-gradient-to-t from-white to-[#e7e7e7]  night:from-[#2b2b2b] night:to-endemicGray",
          " hover:from-[#f1f1f1] hover:to-[#dcdcdc] night:hover:from-[#383838] night:hover:to-[#242424]",
        )}
      >
        {children}
      </button>
    </span>
  );
};

export default RoundButton;
