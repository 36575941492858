"use client";
import { twMerge } from "tailwind-merge";
import { Button } from "../Button";
import ArrowDownIcon from "@/assets/icons/arrow-down.svg";

export interface Props {
  fetchNextPage: () => void;
  label?: string;
  className?: string;
}

const ShowMoreButton = ({ fetchNextPage, label, className }: Props) => {
  return (
    <div className={twMerge("flex justify-center mt-10", className)}>
      <Button
        endIcon={
          <ArrowDownIcon className="w-[10px] fill-current endemic:fill-invert" />
        }
        onClick={() => fetchNextPage()}
      >
        {label || "Show more"}
      </Button>
    </div>
  );
};

export default ShowMoreButton;
