"use client";
import React from "react";
import styles from "./HeroItem.module.css";
import { twMerge } from "tailwind-merge";
import { EndemicImage } from "@/app/(components)/Basic/Image";
import { ImagePlaceholder } from "@/app/(components)/Basic/Image/types";

export interface Props {
  item: { image: string; imagePlaceholder?: ImagePlaceholder };
}

function HeroItem({ item }: Props) {
  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 lg:relative lg:bottom-auto lg:left-auto lg:top-auto lg:right-auto h-full lg:max-w-[1118px]">
      {item.image && (
        <EndemicImage
          src={item.image}
          placeholder={item.imagePlaceholder}
          className={twMerge(styles.heroMask, "max-h-full object-cover")}
          sizes="(max-width: 768px) 100vw, 55vw"
          fill
          priority
          alt="Endemic"
          dynamic
        />
      )}
      {/* {!item.image && item.video?.url && (
        <video
          autoPlay
          loop
          className={twMerge(
            styles.heroMask,
            "max-h-full w-full object-cover h-full",
          )}
        >
          <source src={item.video.url} />
        </video>
      )} */}
    </div>
  );
}

export default HeroItem;
