import { useWeb3State } from "@/web3/context";
import { useMemo } from "react";
import { getNetworkPaymentMethods } from "../config/blockchain/helpers";

const usePaymentCurrency = (
  paymentErc20TokenAddress: string | null | undefined,
) => {
  const { network } = useWeb3State();

  return useMemo(() => {
    if (!paymentErc20TokenAddress) return;

    const paymentMethods = getNetworkPaymentMethods(network);

    if (!paymentMethods) return;

    return paymentMethods[paymentErc20TokenAddress?.toLowerCase()];
  }, [network, paymentErc20TokenAddress]);
};

export default usePaymentCurrency;
