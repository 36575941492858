"use client";
import { useMemo } from "react";
import { DropdownInputBase } from "../DropdownInputBase";
import { OptionValue } from "../DropdownInputBase/types";
import { PropsSingle } from "../DropdownInputBase/DropdownInputBase";

function DropdownInput<T extends OptionValue>({
  options,
  selectedOption,
  setSelectedOption,
  label,
  ...props
}: PropsSingle<T>) {
  const dropdownLabel = useMemo(() => {
    const option = options?.find(
      (option: any) => option.value === selectedOption,
    );
    return !!label ? (
      <>
        {label} {option?.label}
      </>
    ) : (
      <>{option?.label}</>
    );
  }, [options, selectedOption]);

  return (
    <DropdownInputBase
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      label={dropdownLabel}
      {...props}
    />
  );
}

export default DropdownInput;
