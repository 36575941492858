import { Colors, TEXT_COLOR_VARIANTS } from "@/shared/constants";
import { twMerge } from "tailwind-merge";
import { Quote as QuoteType } from "@/api/contentful/types";
import classes from "./Quote.module.css";
import { Button } from "../Button";
import TwitterIcon from "@/assets/icons/logo-twitter.svg";
import reactToText from "react-to-text";

export interface Props {
  quote: QuoteType;
}

/**
 * TODO:
 * define mobile version
 * define how default tweet format should look like
 */
const Quote = ({ quote }: Props) => {
  return (
    <div className="flex flex-col justify-start">
      <div
        className={twMerge(
          `text-[40px] leading-[44px] font-light text-left gradient tracking-tighter p-2 ${
            TEXT_COLOR_VARIANTS[Colors.Yellow]
          } `,
          "sm:text-[64px] sm:leading-[64px]",
          classes.text,
        )}
      >
        {quote.text}
      </div>

      <div className="flex flex-wrap flex-row items-start mt-10">
        <div
          className={
            "flex items-center font-light text-2xl lg:text-3xl mr-6 mb-5 tracking-tighter before:content-[''] before:w-20 before:h-[1px] before:inline-block before:mr-6 night:before:bg-endemicGray-900 light:before:bg-endemicGray-500 endemic:before:bg-[#ffffff88]"
          }
        >
          {quote.artist?.name}
        </div>
        <Button
          href={`https://twitter.com/intent/tweet?text=${encodeURI(
            `${reactToText(quote.text)}\n\n${quote.artist?.name}`,
          )}`}
          className="twitter-share-button font-normal tracking-normal "
          startIcon={<TwitterIcon className="w-5 h-4" />}
        >
          Retweet
        </Button>
      </div>
    </div>
  );
};

export default Quote;
