import { HexColors } from "@/shared/constants";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

export interface Props {
  viewAllUrl: string;
  className?: string;
  color?: string;
}

const ViewAllButton = ({ viewAllUrl, className, color }: Props) => {
  let colorClasses;

  switch (color) {
    case HexColors.Green:
      colorClasses = "text-green before:bg-green";
      break;
    case HexColors.Red:
      colorClasses = "text-red before:bg-red";
      break;
    case HexColors.Yellow:
      colorClasses = "text-yellow before:bg-yellow";
      break;
    default:
      colorClasses = "text-red before:bg-red";
  }

  return (
    <Link
      className={twMerge(
        `flex items-center text-lg before:content-[''] before:inline-block before:h-[1px] before:w-[100px] before:mr-3 before:endemic:bg-white endemic:text-white`,
        className,
        colorClasses,
      )}
      href={viewAllUrl}
    >
      View All
    </Link>
  );
};

export default ViewAllButton;
