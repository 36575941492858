"use client";
import { Quote as QuoteType } from "@/api/contentful/types";
import { User } from "@/api/users/types";
import { Quote } from "@/app/(components)/Basic/Quote";
import { Colors } from "@/shared/constants";
import React, { Fragment } from "react";
import { ArtistItem } from "../ArtistItem";
import { Nft } from "@/api/nfts/types";
import { NoItems } from "@/app/(components)/Basic/NoItems";
import { ShowMoreButton } from "@/app/(components)/Basic/ShowMoreButton";
import { isEqual } from "@/shared/string";
import { ProfileItemCard } from "../../Profile/ProfileItemCard";
import { Loading } from "@/app/(components)/Basic/Loading";

export interface Props {
  artistItems: User[];
  featuredNfts?: Nft[];
  quotes?: QuoteType[];
  showArtOnHover?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  randomQuoteIndices?: number[] | undefined;
  isLoading?: boolean;
}

const ArtistItemsWithQuotes = ({
  artistItems,
  featuredNfts,
  quotes,
  showArtOnHover,
  hasNextPage,
  fetchNextPage,
  randomQuoteIndices,
  isLoading,
}: Props) => {
  return (
    <>
      {artistItems.length ? (
        <>
          <div className={"flex flex-col gap-20"}>
            {artistItems.map((artistItem, index) => {
              const swapItemAndAddQuote = index % 2 != 0;
              const quoteIndex =
                randomQuoteIndices && randomQuoteIndices[Math.floor(index / 2)];
              const isQuoteVisible =
                swapItemAndAddQuote && quotes && quoteIndex;

              return (
                <Fragment key={index}>
                  {showArtOnHover ? (
                    <ArtistItem
                      artist={artistItem}
                      featuredNfts={featuredNfts?.filter((nft) =>
                        isEqual(nft.artist.address, artistItem.address),
                      )}
                      swap={swapItemAndAddQuote}
                      color={Object.values(Colors)[index % 3]}
                    />
                  ) : (
                    <ProfileItemCard
                      profile={artistItem}
                      swap={swapItemAndAddQuote}
                      color={Object.values(Colors)[index % 3]}
                    />
                  )}
                  {isQuoteVisible && <Quote quote={quotes[quoteIndex]} />}
                </Fragment>
              );
            })}
          </div>
          {hasNextPage && fetchNextPage && (
            <ShowMoreButton fetchNextPage={fetchNextPage} />
          )}
        </>
      ) : isLoading ? (
        <Loading spinnerClassName="w-16 h-16" />
      ) : (
        <NoItems message="No items to display" />
      )}
    </>
  );
};

export default ArtistItemsWithQuotes;
