import React from "react";

export interface Props {
  activeIndex: number;
  items: { image: string }[];
}

const HeroSwiperIndicator = ({ activeIndex, items }: Props) => {
  return (
    <div className="flex gap-2 mb-10 mx-auto lg:mx-0">
      {items.map((_, i) => {
        return i === activeIndex ? (
          <span
            key={i}
            className="w-4 h-2 bg-black night:bg-white endemic:bg-yellow rounded-md"
          ></span>
        ) : (
          <span
            key={i}
            className="w-2 h-2 bg-[#D9D9D9] night:bg-grey endemic:bg-[#00726D] rounded-md"
          ></span>
        );
      })}
    </div>
  );
};

export default HeroSwiperIndicator;
