import React from "react";
import { ViewAllButton } from "../../Basic/ViewAllButton";

export interface Props {
  title?: string;
  viewAllUrl?: string;
}

function MoreItemsSectionHeader({ title, viewAllUrl }: Props) {
  return (
    <div className="flex justify-between mb-5">
      <div className="text-primary text-2xl">{title || "More news"}</div>
      {viewAllUrl && <ViewAllButton viewAllUrl={viewAllUrl} />}
    </div>
  );
}

export default MoreItemsSectionHeader;
