import { Colors } from "@/shared/constants";
import { twJoin, twMerge } from "tailwind-merge";
import DefaultProfile from "@/assets/graphics/default-profile.svg";
import Link from "next/link";
import { sliceFullName } from "@/api/users/helpers";
import { ImageTypeHandler } from "@/app/(components)/Basic/ImageTypeHandler";
import { EndemicUserWrapper } from "@/app/(components)/Basic/EndemicUserWrapper";
import { Profile } from "../../Profile/types";
import { getProfileName } from "../../Profile/helpers";
import { getUsersHref, addDisplayNamePrefix } from "@/api/users/helpers";

export interface Props {
  profile: Profile;
  swap: boolean;
  color: Colors;
  baseHref?: string;
}

const ProfileItemCard = ({ profile, color, swap, baseHref = "" }: Props) => {
  const name =
    profile.fullName ||
    addDisplayNamePrefix(profile.displayName) ||
    profile.name;

  const dividedName = name ? sliceFullName(name) : [];

  const href = baseHref + getUsersHref(profile);

  return (
    <>
      <div
        className={twMerge(
          "flex justify-center md:flex-row md:items-center gap-y-2",
          swap ? "flex-col-reverse" : "flex-col",
        )}
      >
        <Link
          href={href}
          className={twMerge(
            "block relative w-full m-automd:m-0  md:max-w-[400px] aspect-square",
            swap && "order-1",
          )}
        >
          {profile.imageUrl ? (
            <ImageTypeHandler
              src={profile.imageUrl}
              placeholder={profile.imagePlaceholder}
              alt={getProfileName(profile) || "profile image"}
              fill
              className="object-cover w-full"
            />
          ) : (
            <DefaultProfile />
          )}
        </Link>
        <Link
          href={href}
          className={twMerge(
            "md:relative flex m-auto md:m-0 flex-col items-center md:basis-1/2 md:max-w-[400px]",
            swap ? "md:items-end md:right-10" : "md:items-start md:left-10",
          )}
        >
          <span
            className={`text-h2-sm font-light lg:text-h2 gradient text-${color} whitespace-nowrap pb-1`}
          >
            {dividedName[0]}
          </span>
          <span
            className={twJoin(
              "text-h1-sm lg:text-h1 font-semibold whitespace-nowrap pb-1",
              `gradient text-${color}`,
            )}
          >
            {dividedName[1]}
          </span>
          <div className="text-secondary text-body-3 mt-2 md:mt-6">
            <EndemicUserWrapper id={profile.id!}>
              {addDisplayNamePrefix(profile.displayName)}
            </EndemicUserWrapper>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProfileItemCard;
