import { PostCategoryType } from "@/api/contentful/types";
import { TEXT_COLOR_VARIANTS } from "@/shared/constants";
import { twMerge } from "tailwind-merge";

export interface Props {
  category: PostCategoryType;
  className?: string;
}

const PostCategory = ({ category, className }: Props) => {
  const textColor =
    category == PostCategoryType.Interview
      ? TEXT_COLOR_VARIANTS.red
      : category == PostCategoryType.News
        ? TEXT_COLOR_VARIANTS.green
        : category == PostCategoryType.ArtAndArtists
          ? TEXT_COLOR_VARIANTS.yellow
          : TEXT_COLOR_VARIANTS.secondary;

  return (
    <div
      className={twMerge(
        textColor,
        "uppercase text-base endemic:text-white",
        className,
      )}
    >
      {category}
    </div>
  );
};

export default PostCategory;
