import { OrderDirection } from "../types";
import { Marketplaces } from "@/app/(components)/Application/Marketplace/constants";
import { ApprovalStage } from "@/app/(components)/Application/Create/Approvals/ApprovalBase/constants";
import { ShortUser } from "../users/types";
import { ImagePlaceholder } from "@/app/(components)/Basic/Image/types";

export enum OrderNftsBy {
  CreatedAt = "createdAt",
  Price = "price",
  ListedAt = "listedAt",
}
export interface AttributeType {
  trait_type: string;
  value: string;
}

export enum ImageOrientation {
  Portrait = "portrait",
  Landscape = "landscape",
  Squared = "squared",
}

export interface ShortNft {
  readonly id: string;
  readonly name: string;
  readonly image: string;
  readonly thumbnail?: string;
  readonly imageWidth: number;
  readonly imageHeight: number;
  readonly imageOrientation: ImageOrientation;
  readonly mediaType?: string;
  readonly placeholder?: ImagePlaceholder;
  readonly artist: ShortUser;
  readonly marketplace?: Marketplaces;
  readonly price?: string;
  readonly paymentErc20TokenAddress?: string;
  readonly blockchain?: string;
  readonly externalUrl?: string;
  readonly animationUrl?: string;
}

export interface Nft extends ShortNft {
  readonly id: string;
  readonly burned: boolean;
  readonly category: string;
  readonly updatedAt: string;
  readonly createdAt: string;
  readonly royalties: string;
  readonly tokenId: string;
  readonly tokenURI: string;
  readonly description: string;
  readonly originalImage: string;
  readonly attributes: AttributeType[];
  readonly chainId: number;
  readonly contractId: string;
  readonly isOnSale?: boolean;
  readonly isAiGenerated?: boolean;
  readonly audioGuide?: string;
  readonly owner: ShortUser;
  readonly contract?: NftContract;
}

export interface PopularNft {
  readonly id: string;
  readonly nft: Nft;
}

export interface NftLike extends ShortNft {
  readonly userId: string;
  readonly nftId: string;
}

export interface NftContract {
  id: string;
  royalties: string;
}

export interface CreateNft {
  id?: string;
  name: string;
  artist: string | ShortUser;
  category: string;
  description: string;
  attributes: AttributeType[];
  artworkFile?: File | null;
  thumbnailFile?: File | null;
  image?: string;
  thumbnail?: string;
  mediaType?: string;
  collection?: string;
}

export interface NftSortQuery {
  nftIds: string[];
  order?: OrderNftsBy;
  orderDirection?: OrderDirection;
  page?: number;
  pageSize?: number;
}

export interface CollectionsNftsQuery {
  collectionIds: string[];
  count?: number;
}

export interface DraftNft extends Nft {
  id: string;
  collection: string;
  stage: ApprovalStage;
  createdAt: string;
  feedback?: string;
  signature?: string;
  tokenCID?: string;
  nonce: string;
}

export interface NftWithCalculatedDimensions extends Nft {
  displayedWidth: number;
  displayedHeight: number;
}

export interface NftFilterQuery {
  take?: number;
  skip?: number;
  marketplaces?: string[];
  onSale?: string;
  searchTerm?: string;
}
