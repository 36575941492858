import FoundationIcon from "@/assets/icons/marketplaces/foundation.svg";
import KnownOriginIcon from "@/assets/icons/marketplaces/known-origin.svg";
import SuperRareIcon from "@/assets/icons/marketplaces/super-rare.svg";
import { ThemedIcon } from "../../Basic/Icon";

export enum Marketplaces {
  ENDEMIC = "ENDEMIC",
  FOUNDATION = "FOUNDATION",
  KNOWN_ORIGIN = "KNOWN_ORIGIN",
  SUPER_RARE = "SUPER_RARE",
}
export const marketplaceIcons = {
  [Marketplaces.FOUNDATION]: <FoundationIcon className="h-4" />,
  [Marketplaces.KNOWN_ORIGIN]: <KnownOriginIcon className="h-4" />,
  [Marketplaces.ENDEMIC]: <ThemedIcon iconName="endy" className="w-4 h-4" />,
  [Marketplaces.SUPER_RARE]: <SuperRareIcon className="h-4" />,
};
