"use client";
import { useRef, useState } from "react";
import PlayIcon from "@/assets/icons/play.svg";

export interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
}

const Player = ({
  src,
  width,
  height,
  poster,
  preload,
  muted,
  autoPlay,
}: Props) => {
  const [started, hasStarted] = useState(autoPlay);
  const videoElement = useRef<HTMLVideoElement | null>(null);

  const handleStart = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (started) return;
    if (!videoElement.current) return;
    event.currentTarget.remove();
    videoElement.current.play();
    hasStarted(true);
  };

  return (
    <div className="relative aspect-video bg-black">
      <div
        className="absolute inset-0 bg-black/50 flex items-center justify-center cursor-pointer z-20"
        onClick={(event) => handleStart(event)}
      >
        <PlayIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
      <video
        ref={videoElement}
        src={src}
        poster={poster}
        height={height}
        width={width}
        preload={preload}
        muted={muted}
        autoPlay={autoPlay}
        controls={started}
        className="flex-1 object-cover"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Player;
