import React from "react";
import { Marketplaces, marketplaceIcons } from "../../../Marketplace/constants";
import { capitalizeToPascalCase } from "@/shared/string";
import usePaymentCurrency from "@/shared/hooks/usePaymentCurrency";
import useDisplayPrice from "@/shared/hooks/useDisplayPrice";
import { twMerge } from "tailwind-merge";

export interface Props {
  marketplace?: Marketplaces;
  blockchain?: string;
  price?: string;
  paymentErc20TokenAddress?: string;
  className?: string;
}

const ArtworkItemTradeInfo = ({
  marketplace,
  blockchain,
  price,
  paymentErc20TokenAddress,
  className,
}: Props) => {
  const paymentMethod = usePaymentCurrency(paymentErc20TokenAddress);

  const displayPrice = useDisplayPrice(price, paymentMethod?.decimals);

  return (
    <div
      className={twMerge("flex items-center justify-between w-full", className)}
    >
      <div className="flex items-center gap-x-4">
        {marketplace ? (
          <span className="flex gap-x-2 items-center">
            {marketplaceIcons[marketplace]}
            <span className="text-[15px] font-medium">
              {capitalizeToPascalCase(marketplace)}
            </span>
          </span>
        ) : null}
        {blockchain ? (
          <span className="text-[15px] font-medium">{blockchain}</span>
        ) : null}
      </div>
      {displayPrice ? (
        <span className="text-[15px] font-medium lg:ml-4 ml-auto block">
          {displayPrice} {paymentMethod?.label}
        </span>
      ) : null}
    </div>
  );
};

export default ArtworkItemTradeInfo;
